import * as Sentry from '@sentry/angular';
import { CookieConsentService } from './cookie-consent.service';
import { environment } from '../../environments/environment';
import { effect, Injectable, signal } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class InitializeSentryService {
  private sentryInitialized = signal<boolean>(false);

  constructor(private cookieConsentService: CookieConsentService) {
    this.initializeSentryOnConsent();
  }

  private initializeSentryOnConsent(): void {
    effect(
      () => {
        const consent = this.cookieConsentService.getConsent();
        if (consent && !this.sentryInitialized()) {
          this.initializeSentry();
          this.sentryInitialized.set(true);
        }
      },
      { allowSignalWrites: true },
    );
  }

  public initializeSentry(): void {
    if (Sentry.getClient() || environment.initializeSentry === false) {
      // Prevent re-initialization
      // console.log('Sentry is already initialized. Skipping...');
      return;
    }

    // console.log('Initializing Sentry...');
    Sentry.init({
      dsn: environment.sentryDsn,
      integrations: [],
      tracesSampleRate: 1.0,
    });
  }
}
