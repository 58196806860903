import { Component, computed, OnInit } from '@angular/core';
import { LandingPageListModel } from '../../models/landing-page-list.model';
import { SettingsModel } from '../../models/setting.model';
import { SettingsService } from '../../services/settings.service';
import { InnerHtmlComponent } from '../inner-html/inner-html.component';
import { TranslationPipe } from '../../pipes/translation.pipe';
import { RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';
import { MatButtonModule } from '@angular/material/button';
import { CookieConsentService } from '../../services/cookie-consent.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
  imports: [
    InnerHtmlComponent,
    TranslationPipe,
    RouterModule,
    CommonModule,
    MatButtonModule,
  ],
  standalone: true,
})
export class FooterComponent implements OnInit {
  settings: SettingsModel = new SettingsModel();
  currentYear = new Date().getFullYear();

  cookieSettingsShow = computed(() => this.cookieConsentService.showConsent());

  locations: LandingPageListModel[] = [];
  pois: LandingPageListModel[] = [];
  propertyTypes: LandingPageListModel[] = [];
  staticPages: LandingPageListModel[] = [];

  constructor(
    private settingsService: SettingsService,
    private cookieConsentService: CookieConsentService,
  ) {}

  ngOnInit(): void {
    this.settings = this.settingsService.settings();

    this.locations = this.settings.LandingPages?.filter(
      (x) => x.Type == 1 && x.ShowInFooter,
    );
    this.pois = this.settings.LandingPages?.filter(
      (x) => x.Type == 2 && x.ShowInFooter,
    );
    this.propertyTypes = this.settings.LandingPages?.filter(
      (x) => x.Type == 3 && x.ShowInFooter,
    );
    this.staticPages = this.settings.LandingPages?.filter(
      (x) => x.Type == 4 && x.ShowInFooter,
    );
  }

  openCookieSettings() {
    this.cookieConsentService.showConsent.set(true);
  }
}
