<footer>
  <div class="container {{ !pois || pois.length === 0 ? 'wide' : '' }}">
    <div class="logo">
      <app-inner-html [html]="settings.Logo"></app-inner-html>
      @if (staticPages && staticPages.length > 0 && settings.ClientId != 4) {
        <ul>
          @for (location of staticPages; track location) {
            <li>
              <a [routerLink]="'/' + location.Url">{{ location.Name }}</a>
            </li>
          }
        </ul>
      }
      @if (settings.ClientId == 4) {
        <div class="hotell-types">
          <p>{{ 37 | translate }}</p>
          <ul>
            @for (location of propertyTypes; track location) {
              <li>
                <a [routerLink]="'/' + location.Url">{{ location.Name }}</a>
              </li>
            }
          </ul>
        </div>
      }
    </div>
    @if (locations && locations.length > 0) {
      <div
        class="locations"
        [ngClass]="{ 'location-columns': settings.ClientId == 4 }"
      >
        <p>
          {{
            settings.ClientId == 4 ? "Populære destinasjoner" : (15 | translate)
          }}
        </p>
        <ul>
          @for (location of locations; track location) {
            <li>
              <a [routerLink]="'/' + location.Url"
                >Hotell {{ location.Name }}</a
              >
            </li>
          }
        </ul>
        @if (settings.ClientId == 4) {
          <a
            class="btn"
            style="display: flex; margin-top: 10px"
            mat-flat-button
            [routerLink]="'/hotell'"
            >Se alle land</a
          >
        }
      </div>
    }
    @if (settings.ClientId != 4) {
      @if (pois && pois.length > 0) {
        <div class="pois">
          <p>{{ 16 | translate }}</p>
          <ul>
            @for (location of pois; track location) {
              <li>
                <a [routerLink]="'/' + location.Url">{{ location.Name }}</a>
              </li>
            }
          </ul>
        </div>
      }
    }
    @if (settings.ClientId != 4) {
      <div class="hotell-types">
        <p>{{ 37 | translate }}</p>
        <ul>
          @for (location of propertyTypes; track location) {
            <li>
              <a [routerLink]="'/' + location.Url">{{ location.Name }}</a>
            </li>
          }
        </ul>
      </div>
    }
  </div>
  <div id="copyright">
    <div class="links">
      <a [routerLink]="'/cookie'">Cookie</a>
      @if (settings.ClientId == 1) {
        <a [routerLink]="'/personuppgiftspolicy'">Personuppgiftspolicy</a>
      } @else {
        <a [routerLink]="'/persondatapolitikk'">Persondatapolitikk</a>
      }
    </div>
    Copyright © {{ currentYear }} HOTELL.NO AS
  </div>
  @if (!cookieSettingsShow()) {
    <div class="cookie-settings" (click)="openCookieSettings()">
      <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path
          fill="#fff"
          d="M266.9 31.9C158.6 30.5 30.3 148.3 32 256c1.8 113.4 142.9 231.4 256 224 95.5-6.2 189.9-119.7 189.8-215.4C477.7 161.1 375.4 36.9 272 32l-5.1-.1zm24.8 21c1 0 1.9.1 2.9.3 16.7 4.1 27.3 37.4 15 49.5-12 11.8-44 .6-48-15.7-3.5-14.4 15.1-34.8 30.1-34.2zm-156.3 78.5c1.4 0 2.7.2 4 .5 17.9 4.1 31.6 34.3 22.5 50.3-7.5 13.1-30.6 9.8-45 5.2-8.9-2.8-21-9.3-21-18.7.3-17.2 20.6-36.9 38.2-37.3h1.3zm238.7 69.5h1.2c12.1.2 24.7 14.9 25 27 .3 10.6-10.4 22.9-21 24-11.5 1.2-26.1-9.5-27.7-21-1.7-12.4 10-29.2 22.5-30zm-141 105.7c15.4 0 26 22.1 27 37.5.7 11.9-5.4 29.7-17.2 31.5-16.1 2.5-33.8-17.6-35.3-33.8-1.3-14.4 11-35.2 25.5-35.2zm188.6 1.1c1.2 0 2.2.1 3.3.4 11.7 3.2 17.8 26.9 9 35.2-8.5 8.1-30.9.9-33.7-10.5-2.5-10.2 10.6-24.8 21.4-25.1zm-269.6 19.9c19.2-.5 36 30.2 30.8 48.7-3.7 13.1-23.1 18.2-36.8 18-9-.1-21.4-4-24-12.7-5.9-19.7 9.4-53.4 30-54zm166.2 87.6 1 .1c11.3 1.1 21.2 17.4 18.8 28.5-2.2 10.1-16.2 18.7-26.3 16.5-8.8-1.9-15.8-13.5-14.9-22.5 1-10.2 11.2-22.8 21.4-22.6z"
        />
      </svg>
    </div>
  }
</footer>
