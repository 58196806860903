@if (settings) {
  <div id="header">
    <div id="header-menu">
      @if (logo) {
        <a [routerLink]="['/']" aria-label="startside" id="logo-wrapper">
          <app-inner-html [html]="logo"></app-inner-html>
        </a>
      }
      @if (settings.ClientId != 4 && hasMenuItems) {
        <nav class="navbar" [ngClass]="{ 'show-menu': showMenu }">
          <div>
            @if (locations && locations.length > 0) {
              <button mat-button [matMenuTriggerFor]="districtMenu">
                {{ 1 | translate }}<span>▾</span>
              </button>
              <mat-menu #districtMenu="matMenu" yPosition="below">
                @for (link of locations; track link) {
                  <a
                    (click)="hideMenu()"
                    mat-menu-item
                    [routerLink]="link.Url"
                    routerLinkActive="active"
                    >{{ link.Name }}</a
                  >
                }
              </mat-menu>
            }
            @if (pois && pois.length > 0) {
              <button mat-button [matMenuTriggerFor]="poiMenu">
                {{ 2 | translate }}<span>▾</span>
              </button>
              <mat-menu #poiMenu="matMenu" yPosition="below">
                @for (link of pois; track link) {
                  <a
                    (click)="hideMenu()"
                    mat-menu-item
                    [routerLink]="link.Url"
                    routerLinkActive="active"
                    >{{ link.Name }}</a
                  >
                }
              </mat-menu>
            }
            @if (propertyTypes && propertyTypes.length > 0) {
              <button mat-button [matMenuTriggerFor]="propertyTypeMenu">
                {{ 36 | translate }}<span>▾</span>
              </button>
              <mat-menu #propertyTypeMenu="matMenu" yPosition="below">
                @for (link of propertyTypes; track link) {
                  <a
                    (click)="hideMenu()"
                    mat-menu-item
                    [href]="link.Url"
                    routerLinkActive="active"
                    >{{ link.Name }}</a
                  >
                }
              </mat-menu>
            }
          </div>
        </nav>
        <button
          [ngClass]="{ open: showMenu }"
          id="nav-icon"
          type="button"
          aria-label="Vis menyen"
          (click)="showMenuToggle()"
        >
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </button>
      }
    </div>
  </div>
}
