import {
  APP_INITIALIZER,
  ApplicationConfig,
  ErrorHandler,
  provideZoneChangeDetection,
} from '@angular/core';
import { provideRouter, Router, withInMemoryScrolling } from '@angular/router';

import { routes } from './app.routes';
import {
  provideClientHydration,
  provideProtractorTestingSupport,
  withEventReplay,
} from '@angular/platform-browser';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { ApiImagePipe } from './pipes/image.pipe';
import { ImageSizePipe } from './pipes/image-size.pipe';
import { SanitizeHtmlPipe } from './pipes/safe-html.pipe';
import { AppService } from './services/app.service';
import { DatePipe } from '@angular/common';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { CustomDateAdapter } from './pipes/custom-date-adapter';
import { CookieConsentService } from './services/cookie-consent.service';
import { InitializeSentryService } from './services/initialize-sentry.service';

import * as Sentry from '@sentry/angular';

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'top',
      }),
    ),
    provideClientHydration(withEventReplay()),
    provideHttpClient(withFetch()),
    ApiImagePipe,
    ImageSizePipe,
    SanitizeHtmlPipe,
    AppService,
    DatePipe,
    CustomDateAdapter,
    provideAnimationsAsync(),
    provideAnimationsAsync(),
    provideAnimationsAsync(),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    provideProtractorTestingSupport(),
    CookieConsentService,
    InitializeSentryService,
  ],
};
