import {
  Injectable,
  Inject,
  Optional,
  PLATFORM_ID,
  Renderer2,
  ElementRef,
} from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { Request } from 'express';
import { signal } from '@angular/core';
import { CookieConsentModel } from '../models/cookie-consent.model';
import { environment } from '../../environments/environment';
import { ReplaySubject } from 'rxjs';

declare let gtag: Function;
declare const ga: any;
export interface AnalyticsEvent {
  type: 'PAGEVIEW' | 'EVENT';
  category?: string;
  action?: string;
  label: string;
}

@Injectable({
  providedIn: 'root',
})
export class CookieConsentService {
  public showConsent = signal<boolean>(false);
  public consent = signal<CookieConsentModel>(
    this.checkConsentFromStorage('init'),
  );
  eventsQueue$ = new ReplaySubject<AnalyticsEvent>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: object,
    @Optional() @Inject('REQUEST') private request: Request,
  ) {
    const hasConsent = this.isBrowser()
      ? this.checkConsentFromStorage('constructor')
      : new CookieConsentModel();
    this.consent.set(hasConsent);
  }

  private isBrowser(): boolean {
    return isPlatformBrowser(this.platformId);
  }

  public checkConsentFromStorage(from: string): CookieConsentModel {
    if (isPlatformServer(this.platformId)) {
      const cookie = this.request?.headers
        ? this.request.headers['cookie'] || null
        : null;
      if (cookie) {
        const cookieModel: CookieConsentModel = JSON.parse(cookie);
        if (cookieModel.Version === environment.cookieVersion) {
          this.updateConsent(cookieModel, 'checkConsentFromStorage');
          this.showConsent.set(false);
          return cookieModel;
        }
      } else {
        this.showConsent.set(true);
      }
    }

    if (isPlatformBrowser(this.platformId)) {
      const cookie = this.getCookie('cookie-consent');
      if (cookie) {
        const cookieModel: CookieConsentModel = JSON.parse(cookie);

        if (cookieModel.Version === environment.cookieVersion) {
          this.showConsent.set(false);
          this.consent.set(cookieModel);

          this.updateConsent(cookieModel, 'checkConsentFromStorage 2');
          return cookieModel;
        }
      } else {
        this.showConsent.set(true);
      }
    }

    return new CookieConsentModel();
  }

  public deleteCookie(cookieName: string) {
    this.setCookie(cookieName, '', -1);
  }

  public setCookie(
    cookieName: string,
    cookieValue: string,
    expireDays?: number,
  ) {
    let d: Date = new Date();
    d.setTime(d.getTime() + (expireDays ?? 30) * 24 * 60 * 60 * 1000);
    let expires: string = 'expires=' + d.toUTCString();
    document.cookie =
      cookieName + '=' + cookieValue + ';' + expires + ';path=/';
  }

  private getCookie(cookieName: string): string | null {
    let name = cookieName + '=';
    let decodedCookie = decodeURIComponent(document.cookie);
    let ca = decodedCookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
    return null;
  }

  updateConsent(consents: CookieConsentModel, from: string = '') {
    // console.log('comesFrom :>> ', from);
    window.dataLayer = window.dataLayer || [];

    consents.Version = environment.cookieVersion;
    this.setCookie('cookie-consent', JSON.stringify(consents), 365);

    // console.log('gtag :>> ', typeof window.gtag);

    if (typeof window.gtag === 'function') {
      gtag('consent', 'update', {
        ad_storage: consents.Marketing ? 'granted' : 'denied',
        analytics_storage: consents.Analytics ? 'granted' : 'denied',
        ad_user_data: consents.Marketing ? 'granted' : 'denied',
        functionality_storage: consents.Functional ? 'granted' : 'denied',
        ad_personalization: consents.Marketing ? 'granted' : 'denied',
      });
    }
    this.showConsent.set(false);
  }

  public getConsent(): boolean {
    return this.showConsent();
  }

  loadTagManagerScript(
    googleTagManagerId: string,
    renderer2: Renderer2,
    elementRef: ElementRef,
  ) {
    const script = renderer2.createElement('script') as HTMLScriptElement;
    script.innerHTML = `
        (function(w, d, s, l, i) {
          w[l] = w[l] || [];
          w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
          var f = d.getElementsByTagName(s)[0],
            j = d.createElement(s),
            dl = l != 'dataLayer' ? '&l=' + l : '';
          j.async = true;
          j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
          f.parentNode.insertBefore(j, f);
        })(window, document, 'script', 'dataLayer', '${googleTagManagerId}');
        
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('consent', 'default', {
          'ad_storage': 'denied',
          'analytics_storage': 'denied',
          'ad_user_data': 'denied',
          'functionality_storage': 'denied',
          'ad_personalization': 'denied',
        });
      `;
    renderer2.appendChild(elementRef.nativeElement, script);

    const noscript = renderer2.createElement('noscript') as HTMLElement;
    // noscript.innerHTML = `
    //     <iframe src="https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}"
    //     height="0" width="0" style="display:none;visibility:hidden"></iframe>
    //   `;
    // renderer2.appendChild(elementRef.nativeElement, noscript);

    // this.subscribeToEvents();

    // this.updateConsent(this.consent(), 'loadTagManagerScript 2');

    const iframe = renderer2.createElement('iframe') as HTMLIFrameElement;
    iframe.src = `https://www.googletagmanager.com/ns.html?id=${googleTagManagerId}`;
    iframe.height = '0';
    iframe.width = '0';
    iframe.style.display = 'none';
    iframe.style.visibility = 'hidden';

    iframe.onload = () => {
      this.checkConsentFromStorage('loadTagManagerScript');
    };

    noscript.appendChild(iframe);
    renderer2.appendChild(elementRef.nativeElement, noscript);
  }

  private subscribeToEvents(): void {
    this.eventsQueue$.subscribe((e: AnalyticsEvent) => {
      if (e.type === 'PAGEVIEW') {
        ga('send', {
          hitType: 'pageview',
          page: e.label,
        });
      } else if (e.type === 'EVENT') {
        ga('send', {
          hitType: 'event',
          eventCategory: e.category,
          eventAction: e.action,
          eventLabel: e.label,
        });
      }
    });
  }

  public trackVirtualPageview(url: string): void {
    if (isPlatformBrowser(this.platformId) && window && window.dataLayer) {
      window.dataLayer.push({
        event: 'virtualPageview',
        page: url,
      });
    }
  }
}
