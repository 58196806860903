import { CommonModule, DOCUMENT, isPlatformBrowser } from '@angular/common';
import {
  AfterViewInit,
  Component,
  computed,
  effect,
  ElementRef,
  Inject,
  OnInit,
  PLATFORM_ID,
  Renderer2,
} from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { SettingsModel } from './models/setting.model';
import { AppService } from './services/app.service';
import { SettingsService } from './services/settings.service';
import { HeaderComponent } from './parts/header/header.component';
import { FooterComponent } from './parts/footer/footer.component';
import { TranslationPipe } from './pipes/translation.pipe';
import { MatButtonModule } from '@angular/material/button';
import { CookieConsentService } from './services/cookie-consent.service';
import { InitializeSentryService } from './services/initialize-sentry.service';
import { InnerHtmlComponent } from './parts/inner-html/inner-html.component';
import { CookieConsentModel } from './models/cookie-consent.model';
import { FormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatExpansionModule } from '@angular/material/expansion';
import { filter } from 'rxjs';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  imports: [
    RouterModule,
    HeaderComponent,
    FooterComponent,
    TranslationPipe,
    MatButtonModule,
    InnerHtmlComponent,
    FormsModule,
    MatCheckboxModule,
    MatSlideToggleModule,
    CommonModule,
    MatExpansionModule,
  ],
  standalone: true,
})
export class AppComponent implements OnInit {
  title = 'Hotell';
  data?: SettingsModel;
  settings: any;
  loading = true;
  error: any;
  cookieConsentShow = computed(() => this.cookieConsentService.showConsent());

  consent: CookieConsentModel = new CookieConsentModel();

  constructor(
    private appService: AppService,
    private settingsService: SettingsService,
    @Inject(PLATFORM_ID) private platformId: Object,
    @Inject(DOCUMENT) private document: Document,
    private renderer2: Renderer2,
    private cookieConsentService: CookieConsentService,
    private initializeSentryService: InitializeSentryService,
    private elementRef: ElementRef,
    private router: Router,
  ) {
    effect(() => {
      this.consent = this.cookieConsentService.consent();

      this.checkForCookieConsents();
      if (this.consent && this.consent.Version > 0) {
        if (this.consent.Functional && environment.initializeSentry) {
          this.initializeSentryService.initializeSentry();
        }
      }
    });
  }

  ngOnInit(): void {
    this.appService.getData('settings').then((x) => {
      this.data = JSON.parse(x);
      if (this.data) {
        this.setHTMLLanguage();
        this.settingsService.setSettings(JSON.parse(x));
      }
      this.setCssVariables();
      this.setUpAnalytics();
    });

    this.router.events
      .pipe(filter((event) => event instanceof NavigationEnd))
      .subscribe((event: NavigationEnd) => {
        if (
          event.urlAfterRedirects !== '/cookie' &&
          event.urlAfterRedirects !== '/personuppgiftspolicy' &&
          event.urlAfterRedirects !== '/persondatapolitikk'
        ) {
          this.checkForCookieConsents();
        } else {
          if (isPlatformBrowser(this.platformId)) {
            this.cookieConsentService.showConsent.set(false);
            document
              .querySelector('body')
              ?.classList.remove('cookie-consent-active');
          }
        }
      });
  }

  checkForCookieConsents() {
    if (isPlatformBrowser(this.platformId)) {
      if (this.cookieConsentService.showConsent()) {
        document.querySelector('body')?.classList.add('cookie-consent-active');
      } else {
        document
          .querySelector('body')
          ?.classList.remove('cookie-consent-active');
      }
    }
  }

  setCssVariables() {
    if (isPlatformBrowser(this.platformId)) {
      const cssVariables = this.getAllCssValues();
      const sheet = document.styleSheets[0];
      if (sheet) sheet.insertRule(`:root {${cssVariables}}`, 0);
    }
  }

  setHTMLLanguage() {
    if (this.data) {
      if (this.data.ClientId === 1) {
        this.document.documentElement.lang = 'sv';
      } else {
        this.document.documentElement.lang = 'nb';
      }
    }
  }

  getAllCssValues(): string {
    if (!this.data) return '';

    let cssVariables = '';

    let css = [];

    css.push(
      '--header-bg-color:' + (this.data.Colors?.HeaderBgColor ?? '#f76300'),
    );
    css.push('--header-color:' + (this.data.Colors?.HeaderColor ?? '#fff'));
    css.push(
      '--header-bg-transparent-color:' +
        (this.data.Colors?.HeaderTransparentBgColor ?? '#f76300'),
    );
    css.push(
      '--header-transparent-color:' +
        (this.data.Colors?.HeaderTransparentColor ?? '#fff'),
    );

    css.push('--font-color:' + (this.data.Colors?.FontColor ?? '#333'));
    css.push('--link-color:' + (this.data.Colors?.LinkColor ?? '#333'));

    css.push(
      '--button-bg-color:' + (this.data.Colors?.ButtonBgColor ?? '#f76300'),
    );
    css.push('--button-color:' + (this.data.Colors?.ButtonColor ?? '#fff'));

    css.push(
      '--cookie-bg-color:' + (this.data.Colors?.CookieBgColor ?? '#fff'),
    );
    css.push('--cookie-color:' + (this.data.Colors?.CookieColor ?? '#fff'));

    css.push(
      '--section-bg-color:' + (this.data.Colors?.SectionBgColor ?? '#fff'),
    );
    css.push('--section-color:' + (this.data.Colors?.SectionColor ?? '#fff'));

    css.push(
      '--footer-bg-color:' + (this.data.Colors?.FooterBgColor ?? '#fff'),
    );
    css.push('--footer-color:' + (this.data.Colors?.FooterColor ?? '#fff'));

    cssVariables = css.join(';');

    return cssVariables;
  }

  setCookieConsent() {}

  setAllCookies(setAll: boolean) {
    if (setAll) {
      this.consent.Functional = true;
      this.consent.Analytics = true;
      this.consent.Marketing = true;
    }

    this.cookieConsentService.updateConsent(this.consent, 'setAllCookies');

    this.document
      .querySelector('body')
      ?.classList.remove('cookie-consent-active');
  }

  setUpAnalytics() {
    if (this.data && this.data.GoogleTagManagerId) {
      this.cookieConsentService.loadTagManagerScript(
        this.data.GoogleTagManagerId,
        this.renderer2,
        this.elementRef,
      );
    }
  }

  declineAllCookies() {
    this.consent.Functional = false;
    this.consent.Analytics = false;
    this.consent.Marketing = false;

    this.cookieConsentService.updateConsent(this.consent, 'declineAllCookies');

    this.document
      .querySelector('body')
      ?.classList.remove('cookie-consent-active');
  }

  updateConsent() {
    this.cookieConsentService.updateConsent(this.consent);
  }
}
