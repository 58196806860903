import { Routes, UrlMatchResult, UrlSegment } from '@angular/router';

export const routes: Routes = [
  {
    path: '',
    loadComponent: () =>
      import('./pages/home/home.component').then((m) => m.HomeComponent),
  },
  {
    path: 'hotell/:url',
    loadComponent: () =>
      import('./pages/property/property.component').then(
        (m) => m.PropertyComponent,
      ),
  },
  {
    path: 'hotels',
    loadComponent: () =>
      import('./pages/hotel-results/hotel-results.component').then(
        (m) => m.HotelResultsComponent,
      ),
  },
  {
    matcher: hotellresultatMatcher,
    loadComponent: () =>
      import('./pages/kayak-wl/kayak-wl.component').then(
        (m) => m.KayakWlComponent,
      ),
  },
  {
    path: '**',
    loadComponent: () =>
      import('./pages/routing/routing.component').then(
        (m) => m.RoutingComponent,
      ),
  },
];

export function hotellresultatMatcher(
  segments: UrlSegment[],
): UrlMatchResult | null {
  if (segments.length > 0 && segments[0].path === 'hotels') {
    return {
      consumed: segments,
      posParams: {
        segments: new UrlSegment(
          segments
            .slice(1)
            .map((s) => s.path)
            .join('/'),
          {},
        ),
      },
    };
  }
  return null;
}
