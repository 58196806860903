@if (data) {
  <app-header></app-header>
  <router-outlet></router-outlet>
  <app-footer></app-footer>
  @if (cookieConsentShow() && data) {
    <div class="cookie-consent-wrapper">
      <div class="cookie-consent">
        <div>
          <app-inner-html [html]="data.CookieConsentText"></app-inner-html>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title> {{ 32 | translate }} </mat-panel-title>
            </mat-expansion-panel-header>
            <app-inner-html [html]="data.CookieExpirationText"></app-inner-html>
          </mat-expansion-panel>

          <div class="buttons">
            @if (
              consent.Version > 0 ||
              consent.Analytics ||
              consent.Functional ||
              consent.Marketing
            ) {
              <button
                mat-flat-button
                color="primary"
                (click)="setAllCookies(false)"
              >
                {{ 52 | translate }}
              </button>
            } @else {
              <button mat-flat-button (click)="declineAllCookies()">
                {{ 51 | translate }}
              </button>
            }
            <button mat-flat-button (click)="setAllCookies(true)">
              {{ 50 | translate }}
            </button>
          </div>
          <div class="selectors">
            <div class="selector">
              <mat-slide-toggle
                [(ngModel)]="consent.Functional"
                labelPosition="before"
                >{{ 56 | translate }}</mat-slide-toggle
              >
            </div>
            <div class="selector">
              <mat-slide-toggle
                [(ngModel)]="consent.Analytics"
                labelPosition="before"
                >{{ 57 | translate }}</mat-slide-toggle
              >
            </div>
            <div class="selector">
              <mat-slide-toggle
                [(ngModel)]="consent.Marketing"
                labelPosition="before"
                >{{ 58 | translate }}</mat-slide-toggle
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  }
}
