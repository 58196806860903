import { Component, Inject, OnInit, PLATFORM_ID } from '@angular/core';
import { LocationListModel } from '../../models/location-list.model';
import gsap from 'gsap';
import Draggable from 'gsap/Draggable';
import ScrollTrigger from 'gsap/ScrollTrigger';
import { CommonModule, isPlatformBrowser } from '@angular/common';
import { PropertyListModel } from '../../models/property-list.model';
import { SettingsService } from '../../services/settings.service';
import { PoiListModel } from '../../models/poi-list.model';
import { SettingsModel } from '../../models/setting.model';
import { LandingPageListModel } from '../../models/landing-page-list.model';
import { RouterModule } from '@angular/router';
import { InnerHtmlComponent } from '../inner-html/inner-html.component';
import { TranslationPipe } from '../../pipes/translation.pipe';
import { MatMenuModule } from '@angular/material/menu';
import { MatButtonModule } from '@angular/material/button';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  standalone: true,
  imports: [
    RouterModule,
    InnerHtmlComponent,
    TranslationPipe,
    MatMenuModule,
    CommonModule,
    MatButtonModule,
  ],
})
export class HeaderComponent implements OnInit {
  settings?: SettingsModel;
  hotels: PropertyListModel[] = [];
  locations: LandingPageListModel[] = [];
  pois: LandingPageListModel[] = [];
  propertyTypes: LandingPageListModel[] = [];
  showMenu = false;
  isMobile = false;
  logo?: string;
  hasMenuItems = true;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private settingsService: SettingsService,
  ) {}

  ngOnInit(): void {
    this.isMobile = this.settingsService.getMobileStatus();
    this.settings = this.settingsService.settings();

    this.logo = this.settings.Logo;
    this.locations = this.settings.LandingPages?.filter(
      (x) => x.Type == 1 && x.ShowInMenu,
    );
    this.pois = this.settings.LandingPages?.filter(
      (x) => x.Type == 2 && x.ShowInMenu,
    );
    this.propertyTypes = this.settings.LandingPages?.filter(
      (x) => x.Type == 3 && x.ShowInMenu,
    );

    if (
      this.locations.length === 0 &&
      this.pois.length === 0 &&
      this.propertyTypes.length === 0
    ) {
      this.hasMenuItems = false;
    }

    if (isPlatformBrowser(this.platformId)) {
      gsap.registerPlugin(ScrollTrigger, Draggable);
    }

    if (isPlatformBrowser(this.platformId)) {
      const body = document.querySelector('body');
      const scrollBox = gsap.timeline({
        scrollTrigger: {
          trigger: body,
          pin: false,
          start: 'top -100',
          end: 'top -100000',
          toggleClass: { className: 'scrolled', targets: 'body' },
          toggleActions: 'play none none none',
        },
      });
    }
  }

  showMenuToggle() {
    this.showMenu = !this.showMenu;
    if (this.showMenu && isPlatformBrowser(this.platformId)) {
      document.querySelector('body')?.classList.add('modal-open');
    } else {
      document.querySelector('body')?.classList.remove('modal-open');
    }
  }

  hideMenu() {
    this.showMenu = false;
    document.querySelector('body')?.classList.remove('modal-open');
  }
}
